<template>
  <div class="footer">
    <div class="all_cnt">
      <div class="log_cnt">
        <img src="../assets/ssssafe.png" alt="">
        <span>24小时热线电话: 028-64291778</span>
        <span>邮箱: safe@ssssafe.com</span>
        <span>公司地址:成都市双流区新裕路博雅城市广场C座1401</span>
      </div>
      <div class="right_cnt">
        <div class="suport">
          <div class="foot_tit">服务支持</div>
          <div class="to_col">
            <span v-for="s in suports" :key="s">{{s}}</span>
          </div>
        </div>
        <div class="divider"></div>
        <div class="friend">
          <div class="foot_tit">友情链接</div>
          <div class="to_col">
            <a v-for="f in friends" :key="f.name" :href="f.url" target="_blank">{{f.name}}</a>
          </div>
        </div>
        <div class="divider"></div>
        <!-- <div class="us">
          <div class="foot_tit">关于我们</div>
          <div class="to_col">
            <span>公司介绍</span>
            <span>联系我们</span>
          </div>
        </div> -->
        <div class="ewm">
          <div class="foot_tit">关注我们</div>
          <div class="to_col">
            <div style="margin-right:16px;">
              <img src="../assets/safe.jpg" alt="" width="70">
              <div>赛虎科技</div>
            </div>
            <div>
              <img src="../assets/xf.jpg" alt="" width="70">
              <div>玄蜂安全</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy_right">
      <span>Copyright © 赛虎科技 All rights reserved. </span>
      <a href='https://beian.miit.gov.cn/' target="_blank"> 蜀ICP备18000708号-8</a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      suports:['渗透测试','风险评估','安全巡检','应急处理','安全保障','安全培训','攻防演练'],
      friends:[
        {name:'郑州网安',url:'https://www.zzwa.org.cn/'},
        {name:'思名网络',url:'https://www.bestsec.com.cn/'},
        {name:'圈子社区',url:'https://secquan.org/'},
        {name:'辽宁玄蜂',url:'https://www.mbeesec.com/'},
        {name:'密思听科技',url:'https://www.mistiny.com/'},
      ]
    }
  },
}
</script>

<style lang="scss">
  .footer{
    background-color: $dark-base;
    color: $gray-base;
    padding: 60px 0;
    padding-bottom: 40px;
    font-size: 0.9vw;
    .all_cnt{
      display: flex;
      align-items: center;
      justify-content: center;
      .right_cnt{
        display: flex;
        align-items: center;
        justify-content: center;
        .friend{
          .to_col{
            a{
              color: $gray-base;
              text-decoration: none;
              margin-bottom: 12px;
              margin-right: 16px;
            }
          }
        }
      }
      .foot_tit{
        color: #fff;
        font-size: 14px;
        margin-bottom: 24px;
      }
      .to_col{
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 120px;
        span{
          margin-bottom: 12px;
          margin-right: 16px;
        }
      }
      .divider{
        width: 0.5px;
        height: 100px;
        background-color:$gray-base;
        margin: 28px 56px 0 40px ;
      }
      .log_cnt{
        margin-right: 6vw;
        img{
          height: 40px;
          margin-bottom: 20px;
        }
        span{
          display: block;
        }
      }
      .ewm{
        // margin-left: 6vw;
        .to_col{
          flex-direction: row;
          text-align: center;
          img{
            margin-bottom: 10px;
          }
        }
      }
    }
    .copy_right{
      text-align: center;
      margin-top: 30px;
      span{margin-right: 40px;}
      a{color: #fff;}
    }
    @media screen and (max-width: 1080px){
      font-size: 12px;
      .all_cnt{
        flex-direction: column;
        .log_cnt{
          margin-right: unset;
          text-align: center;
          margin-bottom:20px ;
          img{
            margin-bottom: 10px;
          }
        }
        .right_cnt{
          width: 100%;
          padding: 20px 40px;
          justify-content: center;
          .suport{
            display: none;
          }
          .friend{
            width: 140px;
          }
          .divider{
            display: none;
          }
        }
      }
      .copy_right{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        span{
          margin-right: unset;      
        }
      }
    }
  }
</style>