<template>
  <div class="top">
    <div class="main">
      <div class="nav_bar">
        <div class="logo">
          <img src="../assets/ssssafe.png" alt="">
        </div>
        <section class="content">
          <div class="items">
            <div class="menu" @click="jump('homepage')">
                <span>首页</span>
            </div>
          </div>
          <div class="items">
            <div class="menu" @click="jump('comprehensive')">
                <span>综合服务平台</span>
            </div>
          </div>
          <div class="items">
              <div class="menu" @click="jump('service')">
                <span>专项服务</span>
              </div>
          </div>
          <div class="items">
              <div class="menu" @click="jump('about')">
                <span>关于我们</span>
              </div>
          </div>

        </section>
        <div id="menu_button_wrapper">
          <div id="menu_button" @click="openmenu = !openmenu">
            <div id="hamburger">
              <span></span>
              <span class="mid_span"></span>
              <span></span>
            </div>
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
    </div>
    <transition name="el-fade-in-linear">
      <div class="tel_nav_bar" v-show="openmenu">
        <ul class="first_ul">
          <li @click="jump('homepage')">首页</li>       
          <li @click="jump('comprehensive')">综合服务平台</li>
          <li @click="jump('service')">专项服务</li>
          <li @click="jump('about')">关于我们</li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  inject:['reload'],
  data(){
    return{
      isphone:false,
      chanpin:true,
      jiejue:false,
      openmenu:false,
    }
  },
  methods: {
    jump(pathname){
      this.reload();
      this.$router.push({
        name:pathname
      });
    },
    test(v){
      console.log(v)
    }
  },
}
</script>

<style lang="scss" scoped>
  .top ::v-deep{
    // background: $bgc-color;
    .main{
      height: 70px;
      position: relative;
      background-color: $dark-base;
      #menu_button_wrapper{
        display: none;
        padding: 12px;
        color: black;
        z-index: 99;
        #menu_button {
          box-sizing: border-box;
          float: right;
          padding: 5px 10px;
          -webkit-touch-callout: none;
          -webkit-user-select: none;
          -khtml-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          &:hover{
            cursor: pointer;
          }
          #hamburger {
            float: right;
            span{
              display: block;
              background-color: #fff;
              width: 16px;
              height: 2px;
              border-radius: 1px;
            }
            .mid_span{
              margin: 3px 0;
            }
          }
        }
      }
      .nav_bar {
        margin-right: 84px;
        font-size: 16px;
        font-weight: 600;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .logo{
          padding-left: 64px;
          margin-right: 28px;
          img{
            height: 35px;
          }
        }
        //菜单
        .content {
          display: flex;
          flex-direction: row;
          // 菜单项
          .items {
            // width: 100px;
            color: $light-base;
            .menu{
              width: 100%;
              height: 70px;
              line-height: 70px;
              text-align: center;
              // &:hover{
              //   cursor: pointer;
              // }
              &:hover .sec_menu{
                display: block;
              }
              &:hover > span{
                border-bottom: 2px solid $light-base;
                // color: $defult;
              }
              span{
                &:first-of-type{
                  // color: yellow;
                  margin: 0 28px;
                  padding-bottom: 12px;
                  &:hover{
                    // border-bottom: 2px solid rgb(0, 68, 255);
                    cursor: pointer;
                    // color: $defult;
                  }
                }
              }
              .sec_menu{
                position: absolute;
                left:0;
                top:70px;
                display: none;
                background-color: rgba(0,0,0,0.4);
                width: 100vw;
                height: calc(100vh - 70px);
                line-height: normal;
                .text_cnt{
                  background-color: #fff;
                  display: flex;
                  justify-content: flex-start;
                  text-align: left;
                  min-width: 1200px;
                  .left{
                    width: 340px;
                    padding: 60px;
                    background-image: linear-gradient(120deg, $light-base, #f0f5ff);
                    border-right: 1px solid #f0f0f0;
                    .subtit{
                      margin-top: 40px;
                      margin-bottom: 10px;
                      font-size: 26px;
                      color: $dark-base;
                    }
                    .brief{
                      font-size: 14px;
                      font-weight: normal;
                      line-height: 22px;
                      color:$gray-base;
                    }
                  }
                  .right{
                    padding: 30px 60px;
                    .categorize{
                      font-size: 14px;
                      margin-bottom: 40px;
                      color: $dark-base;
                      .one_tit{
                        margin-bottom: 12px;
                      }
                      .one_items{
                        display: flex;                    
                        .the_item{
                          width: 200px;
                          padding-right: 14px;
                          font-weight: normal;
                          cursor: pointer;
                          .item_tit{
                            color: $dark-base;
                          }
                          .item_cnt{
                            color: $gray-base;
                          }
                        }
                      }
                    }
                  }
                }
                // .drop{
                //   // background: red;
                //   text-align: center;
                //   width: 100%;
                //   height: 45px;
                //   line-height: 45px;
                //   overflow: hidden;
                //   &:hover{
                //     cursor: pointer;
                //   }
                // }
              }
            }
          }
        }
      }
    }
    .tel_nav_bar{
      display: none;
      // color:$w-color ;
      // background-color: $bgc-9;
      font-size: 14px;
      position: absolute;
      left: 0;
      top:60px;
      width: 100%;
      height: calc(100vh - 60px);
      overflow-y: auto;
      overflow-x: hidden;
      .first_ul {
        width: 100%;
        background: $dark-base;
        color: #fff;
        padding: 20px;
        font-weight: bold;
        li {
          display: block;
          padding: 10px;
          margin: 0 1vw;
          line-height: 20px;
          i{
            float: right;
            line-height: 20px;
          }
        }
        .sec_ul {
          li{
            padding: 5px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1080px) {
    .top{
      .main{
        justify-content: flex-end;
        .logo{
          width: unset;
        }
        #menu_button_wrapper{
          display: block;
          margin-left: auto;
        }
        .nav_bar{
          margin-right: 10px;
          height: 70px;
          .logo{
            padding-left: 30px;
          }
          .content{
            display: none;
          }
        }
      }
      .tel_nav_bar{
        display: block;
      }
    }
  }
</style>