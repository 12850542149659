<template>
    <div class="wrapper">
      <div class="aside">
        <v-sidebar></v-sidebar>
      </div>   
      <!-- 主题内容 -->
      <div class="container">
        <!-- <keep-alive> -->
          <router-view></router-view>
        <!-- </keep-alive> -->
      </div>
      <div class="foot">
        <v-footer></v-footer>
      </div>
    </div>
</template>

<script>
  import vSidebar from './SideBar.vue';
  import vFooter from './footer.vue'
  export default {
    data(){
        return {
          user:'',
          hasTask:false,
        }
    },
    methods:{},
    components:{
      vSidebar,vFooter
    },
  }
</script>

<style lang="scss">
.wrapper{
  background-color: $light-base;
  .aside{
    position: sticky;
    top:0px;
    z-index: 999;
  }
  .container{
    min-height: calc(100vh - 70px);
  }
  @media screen and (max-width: 1080px){
    .container{
      min-height: unset;
    }
  }  
}
</style>
